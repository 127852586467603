import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Box, Card, CardContent, CardMedia, Container, Typography, useTheme } from "@mui/material";
import { alpha } from '@mui/material/styles';
var align = 'center';
var Home = function () {
    var theme = useTheme();
    var intl = useIntl();
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { sx: {
                background: 'url(/static/images/site_cover.jpg) center center no-repeat',
                backgroundSize: 'cover',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: { xs: 300, sm: 400, md: 400, lg: 400, xl: 400 },
                boxShadow: 1,
            } },
            React.createElement(Container, { maxWidth: "lg", sx: { display: 'flex', alignItems: 'center', justifyContent: 'center' } },
                React.createElement(Card, { sx: {
                        backgroundColor: alpha(theme.palette.background.default, 0.4),
                        backdropFilter: 'blur(2px)',
                    } },
                    React.createElement(CardContent, null,
                        React.createElement(Typography, { variant: "h2", sx: { my: 2 }, align: align },
                            React.createElement(FormattedMessage, { id: "site.name" })),
                        React.createElement(Typography, { variant: "body1", align: align },
                            React.createElement(FormattedMessage, { id: "page.home.intro" })))))),
        React.createElement(Container, { maxWidth: "lg" },
            React.createElement(Box, { sx: {
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    flexWrap: 'wrap',
                    mt: 2,
                } },
                React.createElement(Card, { sx: { maxWidth: 345, my: 2, minHeight: 370 } },
                    React.createElement(CardMedia, { component: "img", image: "/static/images/needs_thumbnail.jpg", alt: intl.formatMessage({ id: 'page.home.user.title.needs' }), sx: { minHeight: 190 } }),
                    React.createElement(CardContent, null,
                        React.createElement(Typography, { variant: "h5", sx: { mb: 2 } },
                            React.createElement(FormattedMessage, { id: "page.home.user.title.needs" })),
                        React.createElement(Typography, { variant: "body2" },
                            React.createElement(FormattedMessage, { id: "page.home.user.intro.needs" })))),
                React.createElement(Card, { sx: { maxWidth: 345, my: 2, minHeight: 370 } },
                    React.createElement(CardMedia, { component: "img", image: "/static/images/locations_thumbnail.jpg", alt: intl.formatMessage({ id: 'page.home.user.title.locations' }), sx: { minHeight: 190 } }),
                    React.createElement(CardContent, null,
                        React.createElement(Typography, { variant: "h5", sx: { mb: 2 } },
                            React.createElement(FormattedMessage, { id: "page.home.user.title.locations" })),
                        React.createElement(Typography, { variant: "body2" },
                            React.createElement(FormattedMessage, { id: "page.home.user.intro.locations" })))),
                React.createElement(Card, { sx: { maxWidth: 345, my: 2, minHeight: 370 } },
                    React.createElement(CardMedia, { component: "img", image: "/static/images/organisations_thumbnail.jpg", alt: intl.formatMessage({ id: 'page.home.user.title.organisations' }), sx: { minHeight: 190 } }),
                    React.createElement(CardContent, null,
                        React.createElement(Typography, { variant: "h5", sx: { mb: 2 } },
                            React.createElement(FormattedMessage, { id: "page.home.user.title.organisations" })),
                        React.createElement(Typography, { variant: "body2" },
                            React.createElement(FormattedMessage, { id: "page.home.user.intro.organisations" }))))),
            React.createElement(Typography, { variant: "h2", sx: { mt: 4 }, align: align },
                React.createElement(FormattedMessage, { id: "page.home.edit.title" })),
            React.createElement(Typography, { variant: "body2", sx: { mb: 2 }, align: align },
                React.createElement(FormattedMessage, { id: "page.home.edit.intro" })),
            React.createElement(Box, { sx: { display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap' } },
                React.createElement(Card, { sx: { maxWidth: 345, my: 2 } },
                    React.createElement(CardMedia, { component: "img", image: "/static/images/locations_edit_thumbnail.jpg", alt: intl.formatMessage({ id: 'page.home.edit.title.locations' }), sx: { minHeight: 190 } }),
                    React.createElement(CardContent, null,
                        React.createElement(Typography, { variant: "h5", sx: { mb: 2 } },
                            React.createElement(FormattedMessage, { id: "page.home.edit.title.locations" })),
                        React.createElement(Typography, { variant: "body2" },
                            React.createElement(FormattedMessage, { id: "page.home.edit.intro.locations" })))),
                React.createElement(Card, { sx: { maxWidth: 345, my: 2 } },
                    React.createElement(CardMedia, { component: "img", image: "/static/images/organisations_edit_thumbnail.jpg", alt: intl.formatMessage({ id: 'page.home.edit.title.organisations' }), sx: { minHeight: 190 } }),
                    React.createElement(CardContent, null,
                        React.createElement(Typography, { variant: "h5", sx: { mb: 2 } },
                            React.createElement(FormattedMessage, { id: "page.home.edit.title.organisations" })),
                        React.createElement(Typography, { variant: "body2" },
                            React.createElement(FormattedMessage, { id: "page.home.edit.intro.organisations" })))),
                React.createElement(Card, { sx: { maxWidth: 345, my: 2 } },
                    React.createElement(CardMedia, { component: "img", image: "/static/images/translations_edit_thumbnail.jpg", alt: intl.formatMessage({ id: 'page.home.edit.title.translations' }), sx: { minHeight: 190 } }),
                    React.createElement(CardContent, null,
                        React.createElement(Typography, { variant: "h5", sx: { mb: 2 } },
                            React.createElement(FormattedMessage, { id: "page.home.edit.title.translations" })),
                        React.createElement(Typography, { variant: "body2" },
                            React.createElement(FormattedMessage, { id: "page.home.edit.intro.translations" }))))))));
};
export default Home;
