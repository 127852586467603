import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Card, CardContent, TextField } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { useIntl } from "react-intl";
import EditLocation from "../edit-location";
import { sortByNames } from "../../utils";
import useApiClient from "../../hooks/useApiClient";
var EditLocations = function (_a) {
    var ids = _a.ids, organisationId = _a.organisationId, _b = _a.initialOpen, initialOpen = _b === void 0 ? true : _b, _c = _a.initialState, initialState = _c === void 0 ? [] : _c;
    var intl = useIntl();
    var _d = useState(initialState.sort(sortByNames)), locations = _d[0], setLocations = _d[1];
    var _e = useState(''), enteredText = _e[0], setEnteredText = _e[1];
    var api = useApiClient('locations');
    var refresh = useCallback(function () {
        (ids
            ? Promise.all(ids.map(function (id) { return api.one(id); }))
            : api.all({ organisationId: organisationId }))
            .then(function (l) { return l.sort(sortByNames); })
            .then(function (o) { return setLocations(o); });
    }, [api, ids, organisationId]);
    useEffect(function () {
        if (!initialState.length)
            refresh();
    }, [refresh, initialState]);
    return (React.createElement(Card, null,
        React.createElement(CardContent, null,
            locations.map(function (location) { return (React.createElement(EditLocation, { key: "edit-location-".concat(location.id), id: location.id, initialOpen: initialOpen, initialState: location, onRemove: refresh })); }),
            React.createElement(Box, { sx: { display: 'flex', flexDirection: 'row', alignItems: 'center' } },
                React.createElement(Box, { sx: { flexGrow: 1 } },
                    React.createElement(TextField, { label: intl.formatMessage({ id: 'input.location.name.new' }), variant: "standard", fullWidth: true, value: enteredText, onChange: function (e) { return setEnteredText(e.target.value); } })),
                React.createElement(Box, null,
                    React.createElement(Button, { variant: "contained", disabled: !enteredText, onClick: function () {
                            api.create({ name: enteredText, organisationId: organisationId }).then(function () {
                                setEnteredText('');
                                refresh();
                            });
                        }, onKeyPress: function (e) {
                            if (e.key === "Enter") {
                                api.create({ name: enteredText, organisationId: organisationId }).then(function () {
                                    setEnteredText('');
                                    refresh();
                                });
                            }
                        } },
                        React.createElement(AddIcon, null)))))));
};
export default EditLocations;
