import React from "react";
import { Link as RLink } from "react-router-dom";
import { Badge, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';
import { useAppSelector } from "../../redux";
import { getOrganisation } from "../../redux/selectors";
var LocationListItem = function (_a) {
    var location = _a.location, needs = _a.needs;
    var organisation = useAppSelector(getOrganisation(location.organisationId));
    if (!organisation) {
        return null;
    }
    var icon = location.location ? React.createElement(LocationOnIcon, null) : React.createElement(NotListedLocationIcon, null);
    return (React.createElement(ListItemButton, { component: RLink, to: "/locations/".concat(location.id) },
        React.createElement(ListItemIcon, null, needs.length ? (React.createElement(Badge, { badgeContent: needs.length, color: "primary" }, icon)) : (icon)),
        React.createElement(ListItemText, { primary: location.name, secondary: organisation.name })));
};
export default LocationListItem;
