var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSlice } from "@reduxjs/toolkit";
var organisationsReducer = createSlice({
    name: 'organisations',
    initialState: {},
    reducers: {
        setOrganisation: function (state, action) {
            var _a;
            return (__assign(__assign({}, state), (_a = {}, _a[action.payload.id] = action.payload, _a)));
        },
        setOrganisations: function (state, action) {
            return action.payload.reduce(function (result, current) {
                var _a;
                return (__assign(__assign({}, result), (_a = {}, _a[current.id] = current, _a)));
            }, {});
        },
    },
});
export default organisationsReducer;
