var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback, useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CircularProgress } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FormattedMessage } from "react-intl";
import EditAccess from "../edit-access";
import EditAccessForm from "../edit-access-form";
import AddIcon from "@mui/icons-material/Add";
import useApiClient from "../../hooks/useApiClient";
import useOrganisations from "../../hooks/useOrganisations";
var EditAccesses = function (_a) {
    var currentCode = _a.currentCode;
    var _b = useState([]), accesses = _b[0], setAccesses = _b[1];
    var _c = useState({ id: 'new', all: true, code: '' }), newAccess = _c[0], setNewAccess = _c[1];
    var organisations = useOrganisations();
    var accessApi = useApiClient('access');
    var refresh = useCallback(function () {
        accessApi.all().then(function (a) { return setAccesses(a.sort(function (a, b) { return a.code.localeCompare(b.code); })); });
    }, [accessApi]);
    var update = useCallback(function (data) {
        accessApi.update(data).then(function () { return refresh(); });
    }, [accessApi, refresh]);
    useEffect(function () {
        refresh();
    }, [refresh]);
    if (!organisations) {
        return React.createElement(CircularProgress, null);
    }
    return (React.createElement(React.Fragment, null,
        accesses.filter(function (a) { return a.code !== currentCode; }).map(function (access) { return (React.createElement(EditAccess, { key: "access-".concat(access.id), access: access, onChange: update, organisations: organisations })); }),
        React.createElement(Accordion, null,
            React.createElement(AccordionSummary, { expandIcon: React.createElement(ExpandMoreIcon, null) },
                React.createElement(FormattedMessage, { id: "page.edit.accesses.new.title" })),
            React.createElement(AccordionDetails, null,
                React.createElement(EditAccessForm, { access: newAccess, organisations: organisations, onChange: setNewAccess }),
                React.createElement(Box, null,
                    React.createElement(Button, { variant: "contained", onClick: function () {
                            var a = __assign({}, newAccess);
                            delete a.id;
                            accessApi.create(a).then(function () {
                                refresh();
                                setNewAccess({ id: 'new', all: true, code: '' });
                            });
                        } },
                        React.createElement(AddIcon, null)))))));
};
export default EditAccesses;
