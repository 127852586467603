import React, { useEffect, useMemo, useState } from "react";
import { Link as RLink, useParams } from "react-router-dom";
import { createStyles, makeStyles } from "@mui/styles";
import { CircularProgress, Container, Link, List, Typography } from "@mui/material";
import MapBlock from "../map-block";
import LocationListItem from "../location-list-item";
import useApiClient from "../../hooks/useApiClient";
import useLocations from "../../hooks/useLocations";
import useNeeds from "../../hooks/useNeeds";
import OrganisationDescription from "../organisation-description";
import { useAppDispatch, useAppSelector } from "../../redux";
import organisationsReducer from "../../redux/organisationsReducer";
import { getOrganisation } from "../../redux/selectors";
var useStyles = makeStyles(function (theme) { return createStyles({
    map: {
        height: '400px',
        width: '100%',
        minWidth: '200px',
        boxSizing: 'border-box',
    },
}); });
var OrganisationRoute = function () {
    var params = useParams();
    var styles = useStyles();
    var api = useApiClient('organisations');
    var organisation = useAppSelector(getOrganisation(params.organisationId));
    var dispatch = useAppDispatch();
    var center = useState({
        lat: 47.497913,
        lng: 19.040236,
    })[0];
    var filter = useMemo(function () { return ({ organisationId: params.organisationId }); }, [params.organisationId]);
    var locations = useLocations(filter);
    var needs = useNeeds();
    useEffect(function () {
        if (!organisation) {
            api.one(params.organisationId)
                .then(function (o) { return dispatch(organisationsReducer.actions.setOrganisation(o)); })
                .catch(console.error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);
    if (!organisation)
        return React.createElement(CircularProgress, null);
    var locationsWithGeo = locations.filter(function (l) { return l.location; });
    return (React.createElement(Container, { maxWidth: "lg" },
        React.createElement(Typography, { variant: "h3", sx: { my: 2 } }, organisation.name),
        React.createElement(MapBlock, { center: center, zoom: 6, className: styles.map, markers: locationsWithGeo.map(function (loc) { return ({
                lat: loc.location.lat,
                lng: loc.location.lng,
                key: "".concat(loc.id, "-marker"),
                popup: (React.createElement(React.Fragment, null,
                    React.createElement(Link, { to: "/locations/".concat(loc.id), component: RLink, color: "inherit", sx: { display: 'block' } }, loc.location.text),
                    React.createElement(Link, { href: "https://maps.google.com/maps?q=".concat(loc.location.lat, ",").concat(loc.location.lng), target: "_blank", color: "inherit", sx: { display: 'block' } }, "Google"))),
            }); }) }),
        organisation.description && (React.createElement(OrganisationDescription, { description: organisation.description })),
        React.createElement(List, null, locations.map(function (loc) { return (React.createElement(LocationListItem, { key: "location-list-item-".concat(loc.id), location: loc, needs: needs.filter(function (n) { return n.locationId === loc.id; }) })); }))));
};
export default OrganisationRoute;
