import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { CircularProgress, Container, Typography } from "@mui/material";
import { useNavigate, useOutlet, useParams } from "react-router-dom";
import useApiToken from "../../hooks/useApiToken";
import useResolveAccess from "../../hooks/useResolveAccess";
var EditRoute = function () {
    var outlet = useOutlet();
    var code = useParams().code;
    var navigate = useNavigate();
    var getToken = useApiToken().getToken;
    var resolveAccess = useResolveAccess();
    useEffect(function () {
        if (code && !getToken()) {
            resolveAccess(code).catch(function () { return navigate('/edit'); });
        }
    }, [code, getToken, navigate, resolveAccess]);
    return (React.createElement(Container, { maxWidth: "lg" },
        React.createElement(Typography, { variant: "h3", sx: { py: 2 } },
            React.createElement(FormattedMessage, { id: "page.edit" })),
        code && !getToken() ? React.createElement(CircularProgress, null) : outlet));
};
export default EditRoute;
