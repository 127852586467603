import Home from "../components/home";
import OrganisationsRoute from "../components/organisations-route";
import OrganisationRoute from "../components/organisation-route";
import LocationsRoute from "../components/locations-route";
import LocationRoute from "../components/location-route";
import Needs from "../components/needs";
import EditRoute from "../components/edit-route";
import EditRouteLogin from "../components/edit-route-login";
import EditRouteProtected from "../components/edit-route-protected";
import NotFoundRoute from "../components/not-found-route";
import React from "react";
import ContactRoute from "../components/contact-route";
var routes = [
    { path: "/", element: React.createElement(Home, null) },
    {
        path: "/organisations",
        children: [
            { index: true, element: React.createElement(OrganisationsRoute, null) },
            { path: ":organisationId", element: React.createElement(OrganisationRoute, null) },
        ],
    },
    {
        path: "/locations",
        children: [
            { index: true, element: React.createElement(LocationsRoute, null) },
            { path: ":locationId", element: React.createElement(LocationRoute, null) },
        ],
    },
    { path: "/needs", element: React.createElement(Needs, null) },
    {
        path: "/edit",
        element: React.createElement(EditRoute, null),
        children: [
            { index: true, element: React.createElement(EditRouteLogin, null) },
            { path: ":code", element: React.createElement(EditRouteProtected, null) },
        ],
    },
    {
        path: "/contact",
        element: React.createElement(ContactRoute, null),
    },
    { path: "*", element: React.createElement(NotFoundRoute, null) }
];
export default routes;
