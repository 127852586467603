import React, { createContext, useEffect, useState } from "react";
import useApiClient from "../../hooks/useApiClient";
export var TranslationsContext = createContext([]);
var TranslationsProvider = function (_a) {
    var children = _a.children;
    var api = useApiClient('translations');
    var _b = useState([]), translations = _b[0], setTranslations = _b[1];
    useEffect(function () {
        api.all().then(function (t) { return setTranslations(t.sort(function (a, b) { return a.id.localeCompare(b.id); })); });
    }, [api]);
    return (React.createElement(TranslationsContext.Provider, { value: translations }, children));
};
export default TranslationsProvider;
