import React, { useEffect, useMemo, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CircularProgress, TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useIntl } from "react-intl";
import debounce from "lodash.debounce";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditLocations from "../edit-locations";
import useApiClient from "../../hooks/useApiClient";
import useLocations from "../../hooks/useLocations";
var EditOrganisation = function (_a) {
    var id = _a.id, initialState = _a.initialState, _b = _a.initialOpen, initialOpen = _b === void 0 ? true : _b, onRemove = _a.onRemove;
    var intl = useIntl();
    var api = useApiClient('organisations');
    var _c = useState(initialOpen), isExpanded = _c[0], setIsExpanded = _c[1];
    var _d = useState(initialState), organisation = _d[0], setOrganisation = _d[1];
    var filters = useMemo(function () { return ({ organisationId: id }); }, [id]);
    var locations = useLocations(filters);
    var debouncedUpdate = debounce(function (data) {
        api.update(data).then(function () { return api.one(data.id); }).then(function (d) { return setOrganisation(d); });
    }, 2000);
    useEffect(function () {
        if (!initialState) {
            api.one(id).then(function (o) { return setOrganisation(o); });
        }
    }, [api, id, initialState]);
    if (!organisation)
        return React.createElement(CircularProgress, null);
    return (React.createElement(Accordion, { expanded: isExpanded, onChange: function (_, ex) { return setIsExpanded(ex); } },
        React.createElement(AccordionSummary, { expandIcon: React.createElement(ExpandMoreIcon, null) }, organisation.name),
        React.createElement(AccordionDetails, null,
            React.createElement(Box, { sx: { display: 'flex', flexDirection: 'row', alignItems: 'center', py: 1 } },
                React.createElement(Box, { sx: { flexGrow: 1, px: 1 } },
                    React.createElement(TextField, { label: intl.formatMessage({ id: 'input.organisation.name' }), variant: "standard", defaultValue: organisation.name, fullWidth: true, onChange: function (e) { return debouncedUpdate({
                            id: organisation.id,
                            description: organisation.description,
                            name: e.target.value,
                        }); }, onKeyPress: function (e) {
                            if (e.key === "Enter") {
                                debouncedUpdate({
                                    id: organisation.id,
                                    description: organisation.description,
                                    // @ts-ignore
                                    name: e.target.value,
                                });
                            }
                        } }),
                    React.createElement(TextField, { label: intl.formatMessage({ id: 'input.organisation.description' }), variant: "standard", defaultValue: organisation.description, fullWidth: true, multiline: true, onChange: function (e) { return debouncedUpdate({
                            id: organisation.id,
                            name: organisation.name,
                            description: e.target.value
                        }); } })),
                React.createElement(Box, null,
                    React.createElement(Button, { variant: "contained", onClick: function () {
                            if (typeof window !== 'undefined' && !window.confirm(intl.formatMessage({ id: 'dialog.confirm.delete' })))
                                return;
                            api.remove(organisation).then(function () { return onRemove && onRemove(); });
                        } },
                        React.createElement(DeleteIcon, null)))),
            isExpanded && (React.createElement(EditLocations, { organisationId: organisation.id, initialOpen: false, initialState: locations })))));
};
export default EditOrganisation;
