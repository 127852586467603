import React, { useCallback } from "react";
import { Box, Button, TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useIntl } from "react-intl";
import debounce from "lodash.debounce";
import useApiClient from "../../hooks/useApiClient";
var EditNeed = function (_a) {
    var need = _a.need, onUpdate = _a.onUpdate, onRemove = _a.onRemove;
    var intl = useIntl();
    var api = useApiClient('needs');
    var update = useCallback(function (data) {
        api.update(data).then(function () { return onUpdate(); });
    }, [api, onUpdate]);
    var debouncedUpdate = useCallback(debounce(function (data) { return update(data); }, 2000), [update]);
    return (React.createElement(Box, { sx: { display: 'flex', flexDirection: 'row', alignItems: 'center', py: 1 } },
        React.createElement(Box, { sx: { flexGrow: 1, px: 1 } },
            React.createElement(TextField, { label: intl.formatMessage({ id: 'input.need.name' }), variant: "standard", defaultValue: need.name, fullWidth: true, onChange: function (e) { return debouncedUpdate({
                    id: need.id,
                    locationId: need.locationId,
                    name: e.target.value,
                }); }, onKeyPress: function (e) {
                    if (e.key === "Enter") {
                        update({
                            id: need.id,
                            locationId: need.locationId,
                            // @ts-ignore
                            name: e.target.value,
                        });
                    }
                } })),
        React.createElement(Box, null,
            React.createElement(Button, { variant: "contained", onClick: function () {
                    if (typeof window !== 'undefined' && !window.confirm(intl.formatMessage({ id: 'dialog.confirm.delete' })))
                        return;
                    api.remove(need).then(function () { return onRemove(); });
                } },
                React.createElement(DeleteIcon, null)))));
};
export default EditNeed;
