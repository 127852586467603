import axios from "axios";
import { useCallback } from "react";
import useApiToken from "../useApiToken";
var useResolveAccess = function () {
    var setToken = useApiToken().setToken;
    return useCallback(function (code) {
        return axios.get("/api/resolve-access/".concat(code)).then(function (response) {
            setToken(response.headers['x-access-token']);
            return response.data;
        });
    }, [setToken]);
};
export default useResolveAccess;
