import { useMemo } from "react";
import axios from "axios";
import useApiToken from "../useApiToken";
import { useIntl } from "react-intl";
var useApiClient = function (resource) {
    var getToken = useApiToken().getToken;
    var intl = useIntl();
    var instance = useMemo(function () {
        return axios.create({
            baseURL: "/api/".concat(resource),
            headers: {
                'x-access-token': getToken(),
                'x-target-locale': intl.locale.split('-')[0],
            },
            validateStatus: function (status) { return status < 400; },
        });
    }, [getToken, intl.locale, resource]);
    return useMemo(function () { return ({
        all: function (params) {
            return instance.get('/', { params: params }).then(function (r) { return r.data; });
        },
        one: function (id) {
            return instance.get("/".concat(id)).then(function (r) { return r.data; });
        },
        create: function (data) {
            return instance.post('/', data).then(function (r) { return r.data; });
        },
        update: function (data) {
            return instance.put("/".concat(data.id), data).then(function (r) { return r.data; });
        },
        remove: function (data) {
            return instance.delete("/".concat(data.id)).then(function () { return Promise.resolve(); });
        },
    }); }, [instance]);
};
export default useApiClient;
