import React from "react";
import { useIntl } from "react-intl";
import { styled } from "@mui/styles";
import { Container } from "@mui/material";
var Iframe = styled('iframe')({
    display: 'block',
    margin: '0 auto',
    maxWidth: '100%',
    width: 640,
    minHeight: 1200,
    border: 0,
    opacity: '1 !important',
});
var ContactRoute = function () {
    var intl = useIntl();
    return (React.createElement(Container, { maxWidth: "lg" },
        React.createElement(Iframe, { src: intl.formatMessage({ id: 'page.contact.iframe' }), style: { opacity: 0 } }, "Loading\u2026")));
};
export default ContactRoute;
