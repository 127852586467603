import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Box, Button, TextField } from "@mui/material";
import LoginIcon from '@mui/icons-material/Login';
import useResolveAccess from "../../hooks/useResolveAccess";
var EditRouteLogin = function () {
    var resolveAccess = useResolveAccess();
    var intl = useIntl();
    var navigate = useNavigate();
    var _a = useState(''), code = _a[0], setCode = _a[1];
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { sx: { display: 'flex', flexDirection: 'row', alignItems: 'center', py: 1 } },
            React.createElement(Box, { sx: { flexGrow: 1, px: 1 } },
                React.createElement(TextField, { label: intl.formatMessage({ id: 'input.edit.login.code' }), variant: "standard", fullWidth: true, value: code, onChange: function (e) { return setCode(e.target.value); }, onKeyPress: function (e) {
                        if (e.key === "Enter") {
                            resolveAccess(code)
                                .then(function () { return navigate("/edit/".concat(code)); })
                                .catch(console.error);
                        }
                    } })),
            React.createElement(Box, null,
                React.createElement(Button, { variant: "contained", disabled: !code, onClick: function () {
                        resolveAccess(code)
                            .then(function () { return navigate("/edit/".concat(code)); })
                            .catch(console.error);
                    } },
                    React.createElement(LoginIcon, null))))));
};
export default EditRouteLogin;
