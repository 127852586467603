import React from "react";
import { Avatar, Box, Button, Card, CardActions, CardHeader, CircularProgress, Container, Link, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { Link as RLink } from "react-router-dom";
import useOrganisations from "../../hooks/useOrganisations";
import useLocations from "../../hooks/useLocations";
var capitalLetters = function (value) {
    return value
        .split('')
        .filter(function (a) { return a.toUpperCase() === a && a.trim() === a; })
        .join('');
};
var OrganisationsRoute = function () {
    var intl = useIntl();
    var organisations = useOrganisations();
    var locations = useLocations();
    return (React.createElement(Container, { maxWidth: "lg" },
        React.createElement(Typography, { variant: "h3", sx: { my: 2 } },
            React.createElement(FormattedMessage, { id: "page.organisations" })),
        React.createElement(Box, { sx: { display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly' } },
            organisations.length === 0 && (React.createElement(CircularProgress, null)),
            organisations.map(function (organisation) { return (React.createElement(Card, { key: "organisation-".concat(organisation.id), sx: {
                    maxWidth: 345,
                    my: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    width: { xs: '100%', sm: '100%', md: '50%', lg: '33%', xl: '33%' },
                } },
                React.createElement(CardHeader, { title: React.createElement(Link, { component: RLink, to: "/organisations/".concat(organisation.id) }, organisation.name), subheader: intl.formatMessage({ id: 'page.organisations.locations.count' }, { count: locations.filter(function (l) { return l.organisationId === organisation.id; }).length }), avatar: React.createElement(Avatar, null, capitalLetters(organisation.name).substring(0, 2)) }),
                React.createElement(CardActions, { sx: { justifyContent: 'flex-end' } },
                    React.createElement(Button, { component: RLink, to: "/organisations/".concat(organisation.id) },
                        React.createElement(FormattedMessage, { id: "page.organisations.more" }))))); }))));
};
export default OrganisationsRoute;
