import React from "react";
import { useLocation } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Box, Container, Typography } from "@mui/material";
var NotFoundRoute = function () {
    var location = useLocation();
    return (React.createElement(Container, { maxWidth: "lg" },
        React.createElement(Box, { sx: { py: 2 } },
            React.createElement(Typography, { variant: "h3" },
                React.createElement(FormattedMessage, { id: "page.notfound.title" })),
            React.createElement(Typography, null,
                React.createElement(FormattedMessage, { id: "page.notfound.description", values: { path: location.pathname } })))));
};
export default NotFoundRoute;
