import useApiClient from "../useApiClient";
import { useEffect } from "react";
import { sortByNames } from "../../utils";
import { useAppDispatch, useAppSelector } from "../../redux";
import { getNeeds } from "../../redux/selectors";
import needsReducer from "../../redux/needsReducer";
var useNeeds = function (filter) {
    var api = useApiClient('needs');
    var needs = useAppSelector(getNeeds());
    var dispatch = useAppDispatch();
    useEffect(function () {
        if (needs.length === 0) {
            api.all()
                .then(function (n) { return dispatch(needsReducer.actions.setNeeds(n)); })
                .catch(console.error);
        }
    }, [api, dispatch, needs.length]);
    return needs
        .filter(function (n) { return filter && filter.search ? n.name.includes(filter.search) : true; })
        .filter(function (n) { return filter && filter.locationId ? n.locationId === filter.locationId : true; })
        .sort(sortByNames);
};
export default useNeeds;
