import React, { useCallback, useContext, useEffect, useState } from "react";
import { Box, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { useIntl } from "react-intl";
import CountryFlag from "../country-flag";
import { TranslationsContext } from "../translations-provider";
var LocaleDropdown = function (_a) {
    var locale = _a.locale, setLocale = _a.setLocale;
    var intl = useIntl();
    var translations = useContext(TranslationsContext);
    var _b = useState(null), anchorElement = _b[0], setAnchorElement = _b[1];
    var open = Boolean(anchorElement);
    var handleClick = useCallback(function (event) {
        setAnchorElement(event.currentTarget);
    }, []);
    var handleClose = useCallback(function () {
        setAnchorElement(null);
    }, []);
    useEffect(function () {
        if (translations.length === 0) {
            return;
        }
        var current = translations.find(function (t) { return t.id === locale; });
        if (current) {
            setLocale(current);
            return;
        }
        current = translations.find(function (t) { return t.id.startsWith(locale); });
        if (current) {
            setLocale(current);
            return;
        }
        setLocale(translations[0]);
    }, [locale, setLocale, translations]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Tooltip, { title: intl.formatMessage({ id: 'language.dropdown.label' }) },
            React.createElement(IconButton, { onClick: handleClick, size: "small", sx: { ml: 2 }, "aria-controls": open ? 'locales-menu' : undefined, "aria-haspopup": "true", "aria-expanded": open ? 'true' : undefined },
                React.createElement(CountryFlag, { code: locale.split('-').pop().toLowerCase(), width: "30" }))),
        React.createElement(Menu, { anchorEl: anchorElement, open: open, onClose: handleClose }, translations.sort(function (a, b) { return a.id.localeCompare(b.id); }).map(function (t) { return (React.createElement(MenuItem, { key: "locale-".concat(t.id), value: t.id, sx: { py: 2 }, onClick: function () {
                setLocale(t);
                handleClose();
            }, "aria-label": t.id },
            React.createElement(CountryFlag, { code: t.id.split('-').pop().toLowerCase(), width: "30" }),
            React.createElement(Box, { sx: { px: 1, display: { xs: 'none', md: 'block' } } }, t.id))); }))));
};
export default LocaleDropdown;
