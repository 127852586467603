import RemarkBreaks from "remark-breaks";
import RemarkGfm from "remark-gfm";
import ReactMarkdown from "react-markdown";
import React from "react";
import { Link } from "@mui/material";
var OrganisationDescription = function (_a) {
    var description = _a.description;
    return (React.createElement(ReactMarkdown, { linkTarget: "_blank", skipHtml: true, unwrapDisallowed: true, children: description, remarkPlugins: [RemarkBreaks, RemarkGfm], components: {
            link: function (p) {
                return React.createElement(Link, { target: "_blank", href: p.href }, p.children);
            },
            a: function (p) {
                return React.createElement(Link, { target: "_blank", href: p.href }, p.children);
            },
        } }));
};
export default OrganisationDescription;
