var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback, useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CircularProgress, Modal, TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditNeeds from "../edit-needs";
import { FormattedMessage, useIntl } from "react-intl";
import debounce from "lodash.debounce";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LocationPicker from "../location-picker";
import useApiClient from "../../hooks/useApiClient";
var EditLocation = function (_a) {
    var id = _a.id, initialState = _a.initialState, _b = _a.initialOpen, initialOpen = _b === void 0 ? true : _b, onRemove = _a.onRemove;
    var intl = useIntl();
    var _c = useState(initialOpen), isExpanded = _c[0], setIsExpanded = _c[1];
    var _d = useState(false), isLocationOpen = _d[0], setIsLocationOpen = _d[1];
    var _e = useState(initialState), location = _e[0], setLocation = _e[1];
    var api = useApiClient('locations');
    var debouncedUpdate = debounce(function (data) {
        api.update(data).then(function () { return refresh(); });
    }, 2000);
    var refresh = useCallback(function () {
        api.one(id).then(function (l) { return setLocation(l); });
    }, [api, id]);
    useEffect(function () {
        if (!initialState)
            refresh();
    }, [refresh, initialState]);
    if (!location) {
        return React.createElement(CircularProgress, null);
    }
    return (React.createElement(Accordion, { key: "edit-location-".concat(location.id), expanded: isExpanded, onChange: function (_, ex) { return setIsExpanded(ex); } },
        React.createElement(AccordionSummary, { expandIcon: React.createElement(ExpandMoreIcon, null) }, location.name),
        React.createElement(AccordionDetails, null,
            React.createElement(Box, { sx: { display: 'flex', flexDirection: 'row', alignItems: 'center', my: 2 } },
                React.createElement(Box, { sx: { flexGrow: 1 } },
                    React.createElement(TextField, { label: intl.formatMessage({ id: 'input.location.name' }), variant: "standard", defaultValue: location.name, fullWidth: true, onChange: function (e) { return debouncedUpdate({
                            id: location.id,
                            organisationId: location.organisationId,
                            location: location.location,
                            name: e.target.value,
                        }); }, onKeyPress: function (e) {
                            if (e.key === "Enter") {
                                debouncedUpdate({
                                    id: location.id,
                                    organisationId: location.organisationId,
                                    location: location.location,
                                    // @ts-ignore
                                    name: e.target.value,
                                });
                            }
                        } })),
                React.createElement(Box, null,
                    React.createElement(Button, { variant: "contained", onClick: function () {
                            if (typeof window !== 'undefined' && !window.confirm(intl.formatMessage({ id: 'dialog.confirm.delete' })))
                                return;
                            api.remove(location).then(function () { return onRemove && onRemove(); });
                        } },
                        React.createElement(DeleteIcon, null)))),
            React.createElement(Box, null,
                React.createElement(Button, { onClick: function () { return setIsLocationOpen(true); } }, location.location ? (React.createElement("small", null, location.location.text)) : (React.createElement(FormattedMessage, { id: "input.location.pick.location.label" })))),
            React.createElement(Modal, { open: isLocationOpen, onClose: function () { return setIsLocationOpen(false); } },
                React.createElement(Box, { sx: {
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                    } },
                    React.createElement(LocationPicker, { picked: location.location, onSave: function (picked) {
                            api.update(__assign(__assign({}, location), { location: picked })).then(function () {
                                refresh();
                                setIsLocationOpen(false);
                            });
                        } }))),
            isExpanded && (React.createElement(EditNeeds, { locationId: location.id })))));
};
export default EditLocation;
