import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Card, CardContent, TextField } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { useIntl } from "react-intl";
import { sortByNames } from "../../utils";
import useApiClient from "../../hooks/useApiClient";
import EditNeed from "../edit-need";
var EditNeeds = function (_a) {
    var locationId = _a.locationId, _b = _a.initialState, initialState = _b === void 0 ? [] : _b;
    var intl = useIntl();
    var _c = useState(initialState.sort(sortByNames)), needs = _c[0], setNeeds = _c[1];
    var _d = useState(''), enteredText = _d[0], setEnteredText = _d[1];
    var api = useApiClient('needs');
    var refresh = useCallback(function () {
        api.all({ locationId: locationId })
            .then(function (l) { return l.sort(sortByNames); })
            .then(function (o) { return setNeeds(o); });
    }, [api, locationId]);
    var create = useCallback(function () {
        api.create({ name: enteredText, locationId: locationId }).then(function () {
            setEnteredText('');
            refresh();
        });
    }, [api, enteredText, locationId, refresh]);
    useEffect(function () {
        refresh();
    }, [refresh]);
    return (React.createElement(Card, null,
        React.createElement(CardContent, null,
            needs.map(function (need) { return (React.createElement(EditNeed, { key: need.id, need: need, onRemove: refresh, onUpdate: refresh })); }),
            React.createElement(Box, { sx: { display: 'flex', flexDirection: 'row', alignItems: 'center', py: 1 } },
                React.createElement(Box, { sx: { flexGrow: 1, px: 1 } },
                    React.createElement(TextField, { label: intl.formatMessage({ id: 'input.need.name.new' }), variant: "standard", fullWidth: true, value: enteredText, onKeyPress: function (e) {
                            if (e.key === "Enter") {
                                create();
                            }
                        }, onChange: function (e) { return setEnteredText(e.target.value); } })),
                React.createElement(Box, null,
                    React.createElement(Button, { variant: "contained", disabled: !enteredText, onClick: function () { return create(); } },
                        React.createElement(AddIcon, null)))))));
};
export default EditNeeds;
