var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { Box, Card, CardContent, Checkbox, FormControlLabel, TextField } from "@mui/material";
import { useIntl } from "react-intl";
import useLocations from "../../hooks/useLocations";
var EditAccessForm = function (_a) {
    var access = _a.access, onChange = _a.onChange, organisations = _a.organisations;
    var intl = useIntl();
    var locations = useLocations();
    return (React.createElement(React.Fragment, null,
        React.createElement(TextField, { label: intl.formatMessage({ id: 'input.access.code' }), variant: "standard", defaultValue: access.code, fullWidth: true, onChange: function (e) { return onChange(__assign(__assign({}, access), { code: e.target.value })); } }),
        React.createElement(FormControlLabel, { label: intl.formatMessage({ id: 'input.access.all.checkbox' }), control: React.createElement(Checkbox, { defaultChecked: "all" in access ? access.all : false, onChange: function (e) {
                    if (e.target.checked) {
                        onChange({
                            id: access.id,
                            code: access.code,
                            translations: access.translations,
                            all: true,
                        });
                    }
                } }) }),
        React.createElement(FormControlLabel, { label: intl.formatMessage({ id: 'input.access.translations.checkbox' }), control: React.createElement(Checkbox, { defaultChecked: access.translations, onChange: function (e) { return onChange(__assign(__assign({}, access), { translations: e.target.checked })); } }) }),
        React.createElement(Card, null,
            React.createElement(CardContent, null, organisations.map(function (organisation) { return (React.createElement(Box, { key: "access-organisation-".concat(access.id, "-").concat(organisation.id) },
                React.createElement(FormControlLabel, { label: organisation.name, control: React.createElement(Checkbox, { checked: "organisationIds" in access && access.organisationIds.includes(organisation.id), 
                        // indeterminate={"locationIds" in access}
                        onChange: function (e) {
                            var a = {
                                id: access.id,
                                code: access.code,
                                translations: access.translations,
                                organisationIds: "organisationIds" in access ? access.organisationIds : []
                            };
                            if (e.target.checked) {
                                a.organisationIds.push(organisation.id);
                            }
                            else {
                                a.organisationIds.splice(a.organisationIds.indexOf(organisation.id), 1);
                            }
                            onChange(a);
                        } }) }),
                React.createElement(Box, { sx: { display: 'flex', flexDirection: 'column', ml: 3 } }, locations.filter(function (l) { return l.organisationId === organisation.id; }).map(function (loc) { return (React.createElement(FormControlLabel, { key: "access-location-".concat(access.id, "-").concat(organisation.id, "-").concat(loc.id), label: loc.name, control: React.createElement(Checkbox, { checked: "locationIds" in access && access.locationIds.includes(loc.id), indeterminate: "organisationIds" in access && access.organisationIds.includes(loc.organisationId), onChange: function (e) {
                            var a = {
                                id: access.id,
                                code: access.code,
                                translations: access.translations,
                                organisationIds: "locationIds" in access ? access.locationIds : []
                            };
                            if (e.target.checked) {
                                a.organisationIds.push(loc.id);
                            }
                            else {
                                a.organisationIds.splice(a.organisationIds.indexOf(loc.id), 1);
                            }
                            onChange(a);
                        } }) })); })))); })))));
};
export default EditAccessForm;
