import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, CircularProgress, Tab, Tabs } from "@mui/material";
import { useIntl } from "react-intl";
import EditOrganisations from "../edit-organisations";
import EditOrganisation from "../edit-organisation";
import EditLocation from "../edit-location";
import EditAccesses from "../edit-accesses";
import EditTranslations from "../edit-translations";
import useApiClient from "../../hooks/useApiClient";
import useResolveAccess from "../../hooks/useResolveAccess";
var TabPanel = function (_a) {
    var value = _a.value, current = _a.current, children = _a.children;
    if (value !== current)
        return null;
    return (React.createElement(Box, { sx: { p: 2 } }, children));
};
var EditRouteProtected = function () {
    var code = useParams().code;
    var intl = useIntl();
    var apiClient = useApiClient('access');
    var resolveAccess = useResolveAccess();
    var _a = useState(), access = _a[0], setAccess = _a[1];
    var navigate = useNavigate();
    var tabs = useMemo(function () {
        if (!access)
            return [];
        return [
            ("all" in access && access.all && {
                label: intl.formatMessage({ id: 'page.edit.organisations.title' }),
                key: 'edit-all-organisations',
            }),
            ("organisationIds" in access && access.organisationIds.length && {
                label: intl.formatMessage({ id: 'page.edit.organisations.title' }),
                key: 'edit-organisations',
            }),
            ("locationIds" in access && access.locationIds && access.locationIds.length && {
                label: intl.formatMessage({ id: 'page.edit.locations.title' }),
                key: 'edit-locations',
            }),
            ("all" in access && access.all && {
                label: intl.formatMessage({ id: 'page.edit.accesses.title' }),
                key: 'edit-accesses',
            }),
            (access.translations && {
                label: intl.formatMessage({ id: 'edit.translations.title' }),
                key: 'edit-translations',
            }),
        ].filter(Boolean);
    }, [access, intl]);
    var _b = useState(), selectedTab = _b[0], setSelectedTab = _b[1];
    useEffect(function () {
        resolveAccess(code)
            .then(setAccess)
            .catch(function () { return navigate('/edit'); });
    }, [apiClient, code, navigate, resolveAccess]);
    useEffect(function () {
        if (tabs.length > 0)
            setSelectedTab(tabs[0].key);
    }, [tabs]);
    if (!access) {
        return React.createElement(CircularProgress, null);
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { sx: { borderBottom: 1, borderColor: 'divider' } },
            React.createElement(Tabs, { value: selectedTab, onChange: function (e, t) { return setSelectedTab(t); }, "aria-label": "tabs" }, tabs.map(function (tab) { return (React.createElement(Tab, { label: tab.label, value: tab.key, key: "tab-control-".concat(tab.key) })); }))),
        React.createElement(TabPanel, { value: "edit-all-organisations", current: selectedTab },
            React.createElement(EditOrganisations, null)),
        React.createElement(TabPanel, { value: "edit-organisations", current: selectedTab }, "organisationIds" in access && access.organisationIds.map(function (id) { return (React.createElement(EditOrganisation, { key: "edit-organisaion-".concat(id), id: id })); })),
        React.createElement(TabPanel, { value: "edit-locations", current: selectedTab }, "locationIds" in access && access.locationIds.map(function (id) { return (React.createElement(EditLocation, { key: "edit-location-".concat(id), id: id })); })),
        React.createElement(TabPanel, { value: "edit-accesses", current: selectedTab },
            React.createElement(EditAccesses, { currentCode: code })),
        React.createElement(TabPanel, { value: "edit-translations", current: selectedTab },
            React.createElement(EditTranslations, null))));
};
export default EditRouteProtected;
