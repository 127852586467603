import { useDispatch, useSelector } from "react-redux";
import locationsReducer from "./locationsReducer";
import organisationsReducer from "./organisationsReducer";
import needsReducer from "./needsReducer";
// Use throughout your app instead of plain `useDispatch` and `useSelector`
export var useAppDispatch = function () { return useDispatch(); };
export var useAppSelector = useSelector;
export var actions = {
    locations: locationsReducer.actions,
    organisations: organisationsReducer.actions,
    needs: needsReducer.actions,
};
export { default as store } from './store';
