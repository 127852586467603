import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditAccessForm from "../edit-access-form";
var EditAccess = function (_a) {
    var access = _a.access, onChange = _a.onChange, organisations = _a.organisations;
    return (React.createElement(Accordion, null,
        React.createElement(AccordionSummary, { expandIcon: React.createElement(ExpandMoreIcon, null) }, access.code),
        React.createElement(AccordionDetails, null,
            React.createElement(EditAccessForm, { access: access, organisations: organisations, onChange: onChange }))));
};
export default EditAccess;
