import useApiClient from "../useApiClient";
import { useEffect } from "react";
import { sortByNames } from "../../utils";
import { useAppDispatch, useAppSelector } from "../../redux";
import { getLocations } from "../../redux/selectors";
import locationsReducer from "../../redux/locationsReducer";
var useLocations = function (filter) {
    var api = useApiClient('locations');
    var locations = useAppSelector(getLocations());
    var dispatch = useAppDispatch();
    useEffect(function () {
        if (locations.length === 0) {
            api.all().then(function (l) { return dispatch(locationsReducer.actions.setLocations(l)); }).catch(console.error);
        }
    }, [api, dispatch, locations.length]);
    return locations
        .filter(function (l) { return filter && filter.organisationId ? l.organisationId === filter.organisationId : true; })
        .sort(sortByNames);
};
export default useLocations;
