var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { useIntl } from "react-intl";
import { Box, Divider, IconButton, List, ListItemButton, ListItemIcon, ListItemText, useTheme } from "@mui/material";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ListIcon from '@mui/icons-material/List';
import EditIcon from '@mui/icons-material/Edit';
import ContactPageIcon from '@mui/icons-material/MailOutline';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Brightness7Icon from "@mui/icons-material/Brightness7";
import Brightness4Icon from "@mui/icons-material/Brightness4";
var RouteLink = function (_a) {
    var children = _a.children, onClick = _a.onClick, to = _a.to, translation = _a.translation;
    var intl = useIntl();
    var resolved = useResolvedPath(to);
    var match = useMatch({ path: resolved.pathname, end: true });
    return (React.createElement(ListItemButton, { component: Link, to: to, selected: !!match, sx: { pr: 4 }, onClick: onClick },
        React.createElement(ListItemIcon, null, children),
        React.createElement(ListItemText, { primary: intl.formatMessage({ id: translation }) })));
};
var SidebarContents = function (_a) {
    var onClose = _a.onClose, toggleThemeMode = _a.toggleThemeMode;
    var theme = useTheme();
    return (React.createElement(Box, null,
        React.createElement(Box, { sx: __assign(__assign({ display: 'flex', alignItems: 'center', padding: theme.spacing(0, 1) }, theme.mixins.toolbar), { justifyContent: 'space-between' }) },
            React.createElement(IconButton, { onClick: toggleThemeMode, color: "inherit" }, theme.palette.mode === 'dark' ? React.createElement(Brightness7Icon, null) : React.createElement(Brightness4Icon, null)),
            React.createElement(IconButton, { onClick: onClose }, theme.direction === 'ltr' ? React.createElement(ChevronLeftIcon, null) : React.createElement(ChevronRightIcon, null))),
        React.createElement(Divider, null),
        React.createElement(List, { component: "nav", "aria-label": "main public" },
            React.createElement(RouteLink, { to: "/", translation: "page.home", onClick: onClose },
                React.createElement(HomeIcon, null)),
            React.createElement(RouteLink, { to: "/organisations", translation: "page.organisations", onClick: onClose },
                React.createElement(CorporateFareIcon, null)),
            React.createElement(RouteLink, { to: "/locations", translation: "page.locations", onClick: onClose },
                React.createElement(LocationOnIcon, null)),
            React.createElement(RouteLink, { to: "/needs", translation: "page.needs", onClick: onClose },
                React.createElement(ListIcon, null)),
            React.createElement(RouteLink, { to: "/contact", translation: "page.contact", onClick: onClose },
                React.createElement(ContactPageIcon, null))),
        React.createElement(Divider, null),
        React.createElement(List, { component: "nav", "aria-label": "secondary protected" },
            React.createElement(RouteLink, { to: "/edit", translation: "page.edit", onClick: onClose },
                React.createElement(EditIcon, null)))));
};
export default SidebarContents;
