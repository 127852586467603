import React, { createContext, useCallback, useState } from "react";
export var ApiTokenContext = createContext({
    setToken: function () { },
    getToken: function () { return ''; },
});
var ApiTokenProvider = function (_a) {
    var children = _a.children, _b = _a.initialToken, initialToken = _b === void 0 ? '' : _b;
    var _c = useState(initialToken), token = _c[0], setToken = _c[1];
    var getToken = useCallback(function () { return token; }, [token]);
    return (React.createElement(ApiTokenContext.Provider, { value: { setToken: setToken, getToken: getToken } }, children));
};
export default ApiTokenProvider;
