import React, { useEffect, useState } from 'react';
import { CircularProgress, Container, Link, List, Typography } from "@mui/material";
import { createStyles, makeStyles } from '@mui/styles';
import { Link as RLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import MapBlock from "../map-block";
import LocationListItem from "../location-list-item";
import useLocations from "../../hooks/useLocations";
import useNeeds from "../../hooks/useNeeds";
import useApiClient from "../../hooks/useApiClient";
import { useAppDispatch, useAppSelector } from "../../redux";
import { getOrganisations } from "../../redux/selectors";
import organisationsReducer from "../../redux/organisationsReducer";
var useStyles = makeStyles(function (theme) { return createStyles({
    map: {
        height: '400px',
        width: '100%',
        minWidth: '200px',
        boxSizing: 'border-box',
    },
}); });
var LocationsRoute = function () {
    var styles = useStyles();
    var locations = useLocations();
    var needs = useNeeds();
    var center = useState({
        lat: 47.497913,
        lng: 19.040236,
    })[0];
    var apiOrganisation = useApiClient('organisations');
    var organisations = useAppSelector(getOrganisations());
    var dispatch = useAppDispatch();
    useEffect(function () {
        if (!organisations.length) {
            apiOrganisation.all()
                .then(function (o) { return dispatch(organisationsReducer.actions.setOrganisations(o)); })
                .catch(console.error);
        }
    }, [apiOrganisation, dispatch, organisations]);
    if (!locations.length || !organisations.length) {
        return React.createElement(CircularProgress, null);
    }
    var locationsWithGeo = locations.filter(function (l) { return l.location; });
    return (React.createElement(Container, { maxWidth: "lg" },
        React.createElement(Typography, { variant: "h3", sx: { my: 2 } },
            React.createElement(FormattedMessage, { id: "page.locations" })),
        React.createElement(MapBlock, { center: center, zoom: 6, className: styles.map, markers: locationsWithGeo.map(function (loc) { return ({
                lat: loc.location.lat,
                lng: loc.location.lng,
                key: "".concat(loc.id, "-marker"),
                popup: (React.createElement(React.Fragment, null,
                    React.createElement(Link, { to: "/locations/".concat(loc.id), component: RLink, color: "inherit", sx: { display: 'block' } }, loc.location.text),
                    React.createElement(Link, { href: "https://maps.google.com/maps?q=".concat(loc.location.lat, ",").concat(loc.location.lng), target: "_blank", color: "inherit", sx: { display: 'block' } }, "Google"))),
            }); }) }),
        React.createElement(List, null, locations.map(function (loc) { return (React.createElement(LocationListItem, { key: "location-list-item-".concat(loc.id), location: loc, needs: needs.filter(function (n) { return n.locationId === loc.id; }) })); }))));
};
export default LocationsRoute;
