var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback, useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, TextField } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FormattedMessage } from "react-intl";
import CountryFlag from "../country-flag";
import debounce from "lodash.debounce";
import CreateTranslationForm from "../create-translation-form";
import useApiClient from "../../hooks/useApiClient";
var DebouncedTextField = function (_a) {
    var defaultValue = _a.defaultValue, helperText = _a.helperText, label = _a.label, onChange = _a.onChange;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    var debouncedOnChange = useCallback(debounce(onChange, 500), [onChange]);
    return (React.createElement(TextField, { label: label, defaultValue: defaultValue, helperText: helperText, fullWidth: true, sx: { my: 2 }, onChange: function (e) { return debouncedOnChange(e.target.value); } }));
};
var EditTranslations = function () {
    var api = useApiClient('translations');
    var _a = useState(), fallback = _a[0], setFallback = _a[1];
    var _b = useState([]), translations = _b[0], setTranslations = _b[1];
    var refresh = useCallback(function () {
        api.all().then(setTranslations);
    }, [api]);
    useEffect(function () {
        api.one('en').then(setFallback);
        refresh();
    }, [api, refresh]);
    if (!fallback) {
        return React.createElement(CircularProgress, null);
    }
    return (React.createElement(React.Fragment, null,
        translations.map(function (translation) { return (React.createElement(Accordion, { key: "edit-translation-".concat(translation.id) },
            React.createElement(AccordionSummary, { expandIcon: React.createElement(ExpandMoreIcon, null) },
                React.createElement(CountryFlag, { code: translation.id.split('-').pop().toLowerCase(), width: "30" })),
            React.createElement(AccordionDetails, null, Object.keys(fallback.translations).sort().map(function (key) { return (React.createElement(DebouncedTextField, { key: "edit-translation-".concat(translation.id, "-input-").concat(key), label: key, defaultValue: translation.translations[key], helperText: fallback.translations[key], onChange: function (text) {
                    var _a;
                    api.update({
                        id: translation.id,
                        translations: __assign(__assign({}, translation.translations), (_a = {}, _a[key] = text, _a))
                    }).then(function () { return refresh(); });
                } })); })))); }),
        React.createElement(Accordion, null,
            React.createElement(AccordionSummary, { expandIcon: React.createElement(ExpandMoreIcon, null) },
                React.createElement(FormattedMessage, { id: "edit.translations.new.title" })),
            React.createElement(AccordionDetails, null,
                React.createElement(CreateTranslationForm, { fallback: fallback.translations, existingIds: translations.map(function (t) { return t.id; }), onSubmit: function (t) {
                        api.update(t).then(function () { return refresh(); }).catch(console.error);
                    } })))));
};
export default EditTranslations;
