import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, TextField } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { useIntl } from "react-intl";
import EditOrganisation from "../edit-organisation";
import { sortByNames } from "../../utils";
import useApiClient from "../../hooks/useApiClient";
var EditOrganisations = function () {
    var intl = useIntl();
    var api = useApiClient('organisations');
    var _a = useState([]), organisations = _a[0], setOrganisations = _a[1];
    var _b = useState(''), enteredText = _b[0], setEnteredText = _b[1];
    var refresh = useCallback(function () {
        api.all().then(function (o) { return setOrganisations(o.sort(sortByNames)); });
    }, []);
    useEffect(function () {
        refresh();
    }, [refresh]);
    return (React.createElement(React.Fragment, null,
        organisations.map(function (organisation) { return (React.createElement(EditOrganisation, { key: "edit-organisation-".concat(organisation.id), id: organisation.id, initialOpen: false, initialState: organisation, onRemove: refresh })); }),
        React.createElement(Box, { sx: { display: 'flex', flexDirection: 'row', alignItems: 'center', py: 1 } },
            React.createElement(Box, { sx: { flexGrow: 1, px: 1 } },
                React.createElement(TextField, { label: intl.formatMessage({ id: 'input.organisation.name.new' }), variant: "standard", fullWidth: true, value: enteredText, onChange: function (e) { return setEnteredText(e.target.value); }, onKeyPress: function (e) {
                        if (e.key === "Enter") {
                            api.create({ name: enteredText }).then(function (o) {
                                setEnteredText('');
                                setOrganisations(o);
                            });
                        }
                    } })),
            React.createElement(Box, null,
                React.createElement(Button, { variant: "contained", disabled: !enteredText, onClick: function () {
                        api.create({ name: enteredText }).then(function (o) {
                            setEnteredText('');
                            setOrganisations(o);
                        });
                    } },
                    React.createElement(AddIcon, null))))));
};
export default EditOrganisations;
