import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link as RLink, useMatch, useResolvedPath, useRoutes } from "react-router-dom";
import { AppBar, Box, Container, createTheme, CssBaseline, Drawer, IconButton, Link, ThemeProvider, Toolbar, Typography, useMediaQuery } from "@mui/material";
import { FormattedMessage, IntlProvider } from "react-intl";
import MenuIcon from '@mui/icons-material/Menu';
import GitHubIcon from '@mui/icons-material/GitHub';
import LocaleDropdown from "../locale-dropdown";
import SidebarContents from "../sidebar-contents";
import ApiTokenProvider from "../api-token-provider";
import TranslationsProvider from "../translations-provider";
import { setCookie } from "../../utils/cookies";
import routes from "../../utils/routes";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import Brightness4Icon from "@mui/icons-material/Brightness4";
var NavLink = function (_a) {
    var to = _a.to, children = _a.children;
    var resolved = useResolvedPath(to);
    var match = useMatch({ path: resolved.pathname, end: true });
    return (React.createElement(Link, { to: to, component: RLink, color: "inherit", sx: { padding: '0 12px' }, underline: match ? 'always' : 'hover' }, children));
};
var getInitialMode = function (prefersDarkMode, storedMode) {
    if (storedMode === 'dark' || storedMode === 'light') {
        return storedMode;
    }
    return prefersDarkMode ? 'dark' : 'light';
};
var App = function (_a) {
    var initialLocale = _a.initialLocale, _b = _a.initialTranslations, initialTranslations = _b === void 0 ? {} : _b, initialMode = _a.initialMode;
    var prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)', { noSsr: typeof window !== 'undefined' });
    var _c = useState(getInitialMode(prefersDarkMode, initialMode)), mode = _c[0], setMode = _c[1];
    var theme = useMemo(function () { return createTheme({
        palette: { mode: mode },
    }); }, [mode]);
    var _d = useState(initialTranslations), messages = _d[0], setMessages = _d[1];
    var _e = useState(initialLocale), locale = _e[0], setLocale = _e[1];
    var _f = useState(false), isSidebarOpen = _f[0], setIsSidebarOpen = _f[1];
    var routesElement = useRoutes(routes);
    var setTranslation = useCallback(function (translation) {
        setLocale(translation.id);
        setMessages(translation.translations);
        if (typeof window !== 'undefined') {
            setCookie('language', translation.id, 7);
        }
    }, []);
    useEffect(function () {
        if (typeof window !== 'undefined')
            setCookie('mode', mode, 14);
    }, [mode]);
    return (React.createElement(ThemeProvider, { theme: theme },
        React.createElement(IntlProvider, { messages: messages, locale: locale, defaultLocale: "en" },
            React.createElement(ApiTokenProvider, null,
                React.createElement(TranslationsProvider, null,
                    React.createElement(Box, { sx: { display: 'flex', flexDirection: 'column', minHeight: '100vh' } },
                        React.createElement(Box, { sx: { flexGrow: 1 } },
                            React.createElement(CssBaseline, null),
                            React.createElement(AppBar, { position: "static" },
                                React.createElement(Container, { maxWidth: "lg" },
                                    React.createElement(Toolbar, null,
                                        React.createElement(IconButton, { size: "large", edge: "start", color: "inherit", "aria-label": "menu", sx: { mr: 2, display: { xs: 'inline-block', md: 'none' } }, onClick: function () { return setIsSidebarOpen(!isSidebarOpen); } },
                                            React.createElement(MenuIcon, null)),
                                        React.createElement(Typography, { variant: "h6", component: RLink, to: "/", color: "inherit", sx: { textDecoration: "none" } },
                                            React.createElement(FormattedMessage, { id: "site.name" })),
                                        React.createElement(Box, { sx: { flexGrow: 1, padding: '0 20px' } },
                                            React.createElement(Box, { sx: { display: { xs: 'none', md: 'block' } } },
                                                React.createElement(NavLink, { to: "/organisations" },
                                                    React.createElement(FormattedMessage, { id: "page.organisations" })),
                                                React.createElement(NavLink, { to: "/locations" },
                                                    React.createElement(FormattedMessage, { id: "page.locations" })),
                                                React.createElement(NavLink, { to: "/needs" },
                                                    React.createElement(FormattedMessage, { id: "page.needs" })),
                                                React.createElement(NavLink, { to: "/edit" },
                                                    React.createElement(FormattedMessage, { id: "page.edit" })),
                                                React.createElement(NavLink, { to: "/contact" },
                                                    React.createElement(FormattedMessage, { id: "page.contact" })))),
                                        React.createElement(Box, { sx: { display: { xs: 'none', md: 'inline-block' } } },
                                            React.createElement(IconButton, { onClick: function () { return setMode(mode === 'dark' ? 'light' : 'dark'); }, color: "inherit" }, theme.palette.mode === 'dark' ? React.createElement(Brightness7Icon, null) : React.createElement(Brightness4Icon, null))),
                                        React.createElement(LocaleDropdown, { locale: locale, setLocale: setTranslation })))),
                            routesElement),
                        React.createElement(AppBar, { position: "static", sx: { mt: 2, py: 1 } },
                            React.createElement(Container, { maxWidth: "lg", sx: { display: 'flex', alignItems: 'center', justifyContent: 'center' } },
                                React.createElement(Link, { href: "https://github.com/Koeroesi86/donatr", target: "_blank", title: "Github", color: "inherit" },
                                    React.createElement(GitHubIcon, null))))),
                    React.createElement(Drawer, { open: isSidebarOpen, onClose: function () { return setIsSidebarOpen(false); } },
                        React.createElement(SidebarContents, { onClose: function () { return setIsSidebarOpen(false); }, toggleThemeMode: function () { return setMode(mode === 'dark' ? 'light' : 'dark'); } })))))));
};
export default App;
