var _a;
import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import App from './components/app';
import { getCookie } from "./utils/cookies";
import { store } from "./redux";
import { Provider } from "react-redux";
if (typeof window !== 'undefined') {
    if (window.location.hash.indexOf('#/') === 0) {
        var target = new URL(window.location.href);
        target.hash = '';
        target.pathname = window.location.hash.replace('#/', '/');
        window.location.replace(target);
    }
    var initialMode = getCookie('mode');
    render(React.createElement(BrowserRouter, null,
        React.createElement(Provider, { store: store },
            React.createElement(App, { initialLocale: getCookie('language') || navigator.language, initialTranslations: window.initialTranslations, initialMode: ('dark' === initialMode || 'light' === initialMode) ? initialMode : undefined }))), document.getElementById('root'));
    (_a = document.querySelector('#server-css')) === null || _a === void 0 ? void 0 : _a.remove();
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('/serviceWorker.js')
            .then(function (registration) {
            // Listen for updates:
            registration.addEventListener('updatefound', function () {
                var newWorker = registration.installing;
                // Listen for when the new worker is ready:
                newWorker.addEventListener('statechange', function () {
                    switch (newWorker.state) {
                        case 'installed':
                            if (navigator.serviceWorker.controller) {
                                console.log('Updated service worker.');
                            }
                            break;
                    }
                });
            });
        })
            .catch(console.error);
    }
}
