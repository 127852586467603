import React, { useCallback, useEffect, useState } from "react";
import debounce from "lodash.debounce";
import { Container, IconButton, InputAdornment, Link, List, ListItem, ListItemIcon, ListItemText, TextField } from "@mui/material";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import { Link as RLink, useSearchParams } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { createStyles, makeStyles } from "@mui/styles";
import ClearIcon from '@mui/icons-material/Clear';
import { sortByNames } from "../../utils";
import MapBlock from "../map-block";
import useApiClient from "../../hooks/useApiClient";
var useStyles = makeStyles(function (theme) { return createStyles({
    map: {
        height: '400px',
        width: '100%',
        minWidth: '200px',
        boxSizing: 'border-box',
    },
}); });
var Needs = function () {
    var intl = useIntl();
    var api = useApiClient('needs');
    var apiLocations = useApiClient('locations');
    var _a = useSearchParams(), searchParams = _a[0], setSearchParams = _a[1];
    var _b = useState(searchParams.has('s')
        ? searchParams.get('s')
        : ''), term = _b[0], setTerm = _b[1];
    var search = useCallback(debounce(function (search) {
        if (searchParams.get('s') !== search) {
            setSearchParams(search ? { s: search } : {});
        }
        api.all({ search: search })
            .then(function (data) { return data.sort(sortByNames); })
            .then(function (data) {
            setListing(data);
            if (search) {
                var locationIds = Array.from(new Set(data.map(function (n) { return n.locationId; })));
                Promise.all(locationIds.map(function (id) { return apiLocations.one(id); }))
                    .then(function (locations) { return locations.filter(function (l) { return l.location; }); })
                    .then(setLocations)
                    .catch(console.error);
            }
            else {
                setLocations([]);
            }
        })
            .catch(console.error);
    }, 200), []);
    var _c = useState([]), listing = _c[0], setListing = _c[1];
    var _d = useState([]), locations = _d[0], setLocations = _d[1];
    var styles = useStyles();
    var center = useState({
        lat: 47.497913,
        lng: 19.040236,
    })[0];
    useEffect(function () {
        search(term);
    }, [term, search]);
    return (React.createElement(Container, { maxWidth: "lg" },
        React.createElement(TextField, { label: intl.formatMessage({ id: 'input.needs.search.label' }), value: term, variant: "standard", onChange: function (e) { return setTerm(e.target.value); }, sx: { my: 2 }, fullWidth: true, InputProps: {
                endAdornment: (React.createElement(InputAdornment, { position: "end" },
                    React.createElement(IconButton, { "aria-label": "toggle password visibility", onClick: function () { return setTerm(''); }, onMouseDown: function () { return setTerm(''); }, edge: "end" },
                        React.createElement(ClearIcon, null))))
            } }),
        React.createElement(List, { sx: { maxHeight: 400, overflow: 'auto', mb: 2 } }, listing.map(function (need) { return (React.createElement(ListItem, { key: "need-".concat(need.id) },
            React.createElement(ListItemIcon, null,
                React.createElement(ShoppingBagIcon, null)),
            React.createElement(ListItemText, { primary: need.name }),
            React.createElement(Link, { to: "/locations/".concat(need.locationId), component: RLink, color: "inherit" },
                React.createElement(FormattedMessage, { id: "page.needs.location.link" })))); })),
        locations.length > 0 && (React.createElement(MapBlock, { center: center, zoom: 6, className: styles.map, markers: locations.map(function (loc) { return ({
                lat: loc.location.lat,
                lng: loc.location.lng,
                key: "".concat(loc.id, "-marker"),
                popup: (React.createElement(React.Fragment, null,
                    React.createElement(Link, { to: "/locations/".concat(loc.id), component: RLink, color: "inherit", sx: { display: 'block' } }, loc.location.text),
                    React.createElement(Link, { href: "https://maps.google.com/maps?q=".concat(loc.location.lat, ",").concat(loc.location.lng), target: "_blank", color: "inherit", sx: { display: 'block' } }, "Google"))),
            }); }) }))));
};
export default Needs;
