import React, { useEffect, useRef, useState } from "react";
import { render } from "react-dom";
import { MapContainer, Marker, Popup, TileLayer, useMapEvents } from "react-leaflet";
import { DomUtil, Control } from "leaflet";
import { Box } from "@mui/material";
import LocationIcon from '@mui/icons-material/GpsFixed';
var LocationMarker = function () {
    var map = useMapEvents({
        locationfound: function (e) {
            var zoom = map.getZoom();
            map.flyTo(e.latlng, zoom < 9 ? zoom * 2 : 18);
        }
    });
    useEffect(function () {
        var centerControl = new Control({ position: 'topleft' });
        centerControl.onAdd = function () {
            var div = DomUtil.create('div', '');
            render(React.createElement(Box, { onClick: function () { return map.locate(); }, sx: {
                    width: '34px',
                    height: '34px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#fff',
                    border: '2px solid rgba(0, 0, 0, 0.2)',
                    color: '#000',
                    borderRadius: '4px',
                    boxSizing: 'border-box',
                    cursor: 'pointer',
                    '&:hover': {
                        backgroundColor: '#f4f4f4',
                    },
                } },
                React.createElement(LocationIcon, { width: 20, height: 20 })), div);
            return div;
        };
        map.addControl(centerControl);
    }, [map]);
    return null;
};
var MapBlock = function (_a) {
    var markers = _a.markers, className = _a.className, initialCenter = _a.center, _b = _a.zoom, zoom = _b === void 0 ? 6 : _b;
    var timer = useRef();
    var center = useState(initialCenter)[0];
    useEffect(function () {
        if (timer.current)
            clearTimeout(timer.current);
    }, [markers]);
    return (React.createElement(MapContainer, { center: center, zoom: zoom, className: className },
        React.createElement(TileLayer, { attribution: '\u00A9 <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors', url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" }),
        React.createElement(LocationMarker, null),
        markers.map(function (marker) { return (React.createElement(Marker, { key: marker.key, position: { lat: marker.lat, lng: marker.lng }, ref: function (m) {
                if (markers.length === 1 && m && m.openPopup) {
                    // @ts-ignore
                    timer.current = setTimeout(function () { return m.openPopup(); }, 10);
                }
            } },
            React.createElement(Popup, null, marker.popup))); })));
};
export default MapBlock;
