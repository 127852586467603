import React, { useEffect, useMemo } from "react";
import { Link as RLink, useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Breadcrumbs, Card, CardContent, CircularProgress, Container, Link, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import MapBlock from "../map-block";
import { createStyles, makeStyles } from "@mui/styles";
import useApiClient from "../../hooks/useApiClient";
import useNeeds from "../../hooks/useNeeds";
import { useAppDispatch, useAppSelector } from "../../redux";
import locationsReducer from "../../redux/locationsReducer";
import organisationsReducer from "../../redux/organisationsReducer";
import { getLocation, getOrganisation } from "../../redux/selectors";
var useStyles = makeStyles(function (theme) { return createStyles({
    map: {
        height: '400px',
        width: '100%',
        minWidth: '200px',
        boxSizing: 'border-box',
    },
}); });
var LocationRouteBreadCrumb = function (_a) {
    var location = _a.location;
    var apiOrganisation = useApiClient('organisations');
    var organisation = useAppSelector(getOrganisation(location.organisationId));
    var dispatch = useAppDispatch();
    useEffect(function () {
        if (!organisation) {
            apiOrganisation.one(location.organisationId)
                .then(function (o) { return dispatch(organisationsReducer.actions.setOrganisation(o)); })
                .catch(console.error);
        }
    }, [apiOrganisation, dispatch, location, organisation]);
    if (!organisation) {
        return React.createElement(CircularProgress, null);
    }
    return (React.createElement(Breadcrumbs, { "aria-label": "breadcrumb", sx: { mt: 2 } },
        React.createElement(Link, { to: "/organisations/".concat(organisation.id), component: RLink, color: "inherit" }, organisation.name),
        React.createElement(Link, { to: "/locations/".concat(location.id), component: RLink, color: "inherit" }, location.name)));
};
var LocationRoute = function () {
    var params = useParams();
    var styles = useStyles();
    var api = useApiClient('locations');
    var location = useAppSelector(getLocation(params.locationId));
    var dispatch = useAppDispatch();
    var filter = useMemo(function () { return ({
        locationId: params.locationId,
    }); }, [params.locationId]);
    var needs = useNeeds(filter);
    useEffect(function () {
        if (!location) {
            api.one(params.locationId)
                .then(function (l) { return dispatch(locationsReducer.actions.setLocation(l)); })
                .catch(console.error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);
    if (!location) {
        return React.createElement(CircularProgress, null);
    }
    return (React.createElement(Container, { maxWidth: "lg" },
        React.createElement(LocationRouteBreadCrumb, { location: location }),
        React.createElement(Typography, { variant: "h3", sx: { my: 2 } }, location.name),
        location.location && (React.createElement(MapBlock, { center: { lat: location.location.lat, lng: location.location.lng }, className: styles.map, zoom: 12, markers: [{
                    lat: location.location.lat,
                    lng: location.location.lng,
                    key: "".concat(location.id, "-marker"),
                    popup: React.createElement(React.Fragment, null,
                        location.location.text,
                        React.createElement(Link, { href: "https://maps.google.com/maps?q=".concat(location.location.lat, ",").concat(location.location.lng), target: "_blank", sx: { display: 'block' } }, "Google")),
                }] })),
        needs.length > 0 && (React.createElement(Card, { sx: { my: 2 } },
            React.createElement(CardContent, null,
                React.createElement(Typography, { variant: "h5" },
                    React.createElement(FormattedMessage, { id: "page.needs" })),
                React.createElement(List, null, needs.map(function (need) { return (React.createElement(ListItem, { key: "location-".concat(location.id, "-need-").concat(need.id) },
                    React.createElement(ListItemIcon, null,
                        React.createElement(ShoppingBagIcon, null)),
                    React.createElement(ListItemText, { primary: need.name }))); })))))));
};
export default LocationRoute;
