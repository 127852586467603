import useApiClient from "../useApiClient";
import { useEffect } from "react";
import { sortByNames } from "../../utils";
import { useAppDispatch, useAppSelector } from "../../redux";
import { getOrganisations } from "../../redux/selectors";
import organisationsReducer from "../../redux/organisationsReducer";
var useOrganisations = function () {
    var api = useApiClient('organisations');
    var organisations = useAppSelector(getOrganisations());
    var dispatch = useAppDispatch();
    useEffect(function () {
        if (organisations.length === 0) {
            api.all().then(function (o) { return dispatch(organisationsReducer.actions.setOrganisations(o)); }).catch(console.error);
        }
    }, [api, dispatch, organisations.length]);
    return organisations.sort(sortByNames);
};
export default useOrganisations;
