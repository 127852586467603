var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback, useEffect, useState } from "react";
import { MapConsumer, MapContainer, Marker, Popup, TileLayer, useMapEvents } from "react-leaflet";
import * as LCG from "leaflet-control-geocoder";
import { Autocomplete, Button, TextField } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import debounce from "lodash.debounce";
// @ts-ignore
var geocoder = new LCG.geocoders.nominatim();
var Picker = function (_a) {
    var onPick = _a.onPick;
    useMapEvents({
        click: function (e) {
            onPick(e.latlng);
        },
    });
    return null;
};
var Search = function (_a) {
    var picked = _a.picked, onSelect = _a.onSelect;
    var intl = useIntl();
    var _b = useState((picked === null || picked === void 0 ? void 0 : picked.text) || ''), term = _b[0], setTerm = _b[1];
    var _c = useState([]), options = _c[0], setOptions = _c[1];
    var search = useCallback(debounce(function (t) {
        geocoder.geocode(t, function (results) {
            setOptions(results.map(function (r) { return ({
                lat: r.center.lat,
                lng: r.center.lng,
                text: r.name,
            }); }));
        });
    }, 1000), []);
    useEffect(function () {
        search(term);
    }, [term, search]);
    return (React.createElement(Autocomplete, { value: picked, inputValue: term, options: options, filterOptions: function (a) { return a; }, filterSelectedOptions: true, autoComplete: true, getOptionLabel: function (option) { return typeof option === 'string' ? option : option.text; }, onChange: function (e, newValue) { return onSelect(newValue); }, onInputChange: function (e, newInputValue) { return setTerm(newInputValue); }, renderInput: function (params) { return (React.createElement(TextField, __assign({}, params, { label: intl.formatMessage({ id: 'input.location.search.location.label' }), fullWidth: true }))); } }));
};
var LocationPicker = function (_a) {
    var onSave = _a.onSave, picked = _a.picked;
    var _b = useState(picked), pickedLocation = _b[0], setPickedLocation = _b[1];
    var center = useState({
        lat: (picked === null || picked === void 0 ? void 0 : picked.lat) || 47.497913,
        lng: (picked === null || picked === void 0 ? void 0 : picked.lng) || 19.040236,
    })[0];
    return (React.createElement(React.Fragment, null,
        React.createElement(Search, { picked: pickedLocation, onSelect: setPickedLocation }),
        React.createElement(MapContainer, { style: { minHeight: '400px' }, center: center, zoom: 6 },
            React.createElement(TileLayer, { url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", attribution: '\u00A9 <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' }),
            React.createElement(MapConsumer, null, function (map) { return (React.createElement(Picker, { onPick: function (p) {
                    geocoder.reverse(p, map.getZoom(), function (results) {
                        if (!results[0])
                            return;
                        var current = results[0];
                        setPickedLocation({
                            lat: current.center.lat,
                            lng: current.center.lng,
                            text: current.name,
                        });
                    });
                } })); }),
            pickedLocation && (React.createElement(Marker, { position: { lat: pickedLocation.lat, lng: pickedLocation.lng }, ref: function (m) { return m && m.openPopup && m.openPopup(); } },
                React.createElement(Popup, null, pickedLocation.text)))),
        React.createElement(Button, { onClick: function () { return onSave(pickedLocation); }, disabled: !pickedLocation },
            React.createElement(FormattedMessage, { id: "input.location.set.location.label" }))));
};
export default LocationPicker;
